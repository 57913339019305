import {
  Button,
  Form,
  GridCol,
  GridRow,
  Heading,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Select,
  SelectOption,
} from '@lmig/lmds-react';
import { useCallback, useState } from 'react';
import CfaUtils from '../utils/CfaUtils';

export interface SalesRepConfirmationProps {
  onConsent: (consent: boolean) => void;
  hasOperatorParticipation?: boolean;
}

const SalesRepConfirmation = ({ onConsent, hasOperatorParticipation }: SalesRepConfirmationProps) => {
  const [consent, setConsent] = useState<string>(CfaUtils.isLocal() ? 'Yes' : 'No');
  const [isOpen, setIsOpen] = useState(true);

  const onSubmit = useCallback(() => {
    setIsOpen(false);
    onConsent(consent === 'Yes' || consent === 'NA');
  }, [consent, onConsent]);

  const handleCancel = useCallback(() => {
    setIsOpen(false);
    onConsent(false);
  }, [onConsent]);

  return (
    <Modal isOpen={isOpen} onClose={handleCancel} isVerticallyCentered={true} size="large">
      <ModalHeader>
        <Heading tag="span" type="h3-light">
          Please read the following to the customer:
        </Heading>
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={onSubmit}>
          <GridRow>
            <GridCol base={12}>
              "When available, we use information from{' '}
              <i>
                <b>third party sources such as connected vehicles or mobile applications</b>
              </i>{' '}
              to provide a customized quote. This may include mileage, time, speed, braking and acceleration data, which will be stored according to
              our retention policies.
              <br />
              Would you like to continue your quote?"
              <br />
              <br />
            </GridCol>
            <GridCol base={6}>
              <div className="center-contents">Does the customer agree to continue?</div>
            </GridCol>
            <GridCol base={6}>
              <div className="center-dropdown">
                <Select id="consentSelect" isRequired labelVisual="Consent" onChange={(e) => setConsent(e.currentTarget.value)} value={consent}>
                  <SelectOption value="No">No</SelectOption>
                  <SelectOption value="Yes">Yes</SelectOption>
                  {hasOperatorParticipation && <SelectOption value="NA">N/A - This is a Service Call</SelectOption>}
                </Select>
              </div>
            </GridCol>
            <GridCol>
              <Heading type="h4">Note</Heading>
              <List>
                <ListItem>Content is necessary to continue quoting this vehicle</ListItem>
                <ListItem>This policy is ineligible for ByMile</ListItem>
                <ListItem>
                  For FAQs, talking points, and other details visit the{' '}
                  <a href="http://www.google.com" target="_blank" rel="noopener noreferrer">
                    Knowledge Center
                  </a>
                </ListItem>
              </List>
            </GridCol>
          </GridRow>
        </Form>
      </ModalBody>
      <ModalFooter>
        <GridRow gutterSize="fixed-16" gutters>
          <GridCol md={6}>
            <Button dynamicWidth onClick={handleCancel}>
              Cancel
            </Button>
          </GridCol>
          <GridCol md={6}>
            <Button dynamicWidth variant="primary" onClick={onSubmit}>
              Apply
            </Button>
          </GridCol>
        </GridRow>
      </ModalFooter>
    </Modal>
  );
};

export default SalesRepConfirmation;
