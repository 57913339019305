import qs from 'qs';
import { useLocation, useRouteMatch } from 'react-router-dom';
import UBI from '../UBI';
import './App.scss';

const App = () => {
  const match = useRouteMatch<{ id: string }>();
  const location = useLocation();

  const { id } = match.params;
  const { cfa } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  console.info({ cfa });

  return (
    <div className="cf">
      <UBI transactionId={id} cfaUrl={cfa?.toString() ?? ''} />
    </div>
  );
};

export default App;
