import { Button, Content, Form, GridCol, GridRow, Heading } from '@lmig/lmds-react';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import TelematicsOperator from '../../model/TelematicsOperator';
import TelematicsProduct from '../../model/TelematicsProduct';
import { RIGHTTRACK_CONTINUOUS_PRICING, RIGHTTRACK_CONTINUOUS_PRICING_DROPDOWN_VALUES, getContinuousPricingDropdownCodes } from '../codes';
import {areAllParticipating, isParticipating} from '../product';
import DebugConsole from './DebugConsole';
import SalesRepConfirmation from './SalesRepConfirmation';
import OperatorProgramSelection from './UBI/OperatorProgramSelection';

export interface ProgramParticipationProps {
  telematicsProducts: TelematicsProduct[];
  policyEffectiveDate: string;
  transactionEffectiveDate: string;
  onPost: (body: any) => Promise<void>;
  isRTAdmin: boolean;
  bindValidationBypass: boolean;
  setError?: Function;
  version?: string;
  build?: string;
}

//TODO: move to central calling location
const newTelematicsProduct = (): TelematicsProduct => ({
  productCode: '0000',
  productLabel: 'Unknown',
  telematicsOperators: [],
  telematicsVehicles: [],
});

const closeWindow = () => {
  console.info('[CLOSE]');
  window.close();
};

const ProgramParticipation = ({
  telematicsProducts,
  policyEffectiveDate,
  transactionEffectiveDate,
  onPost,
  bindValidationBypass,
  isRTAdmin,
  setError,
  version,
  build,
}: ProgramParticipationProps) => {
  const [operators, setOperators] = useState<TelematicsOperator[]>([]);
  const [product, setProduct] = useState<TelematicsProduct>(newTelematicsProduct());

  const handleSubmit = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      e.stopPropagation();

      const body = {
        telematicsProducts: telematicsProducts
          .filter((x) => x.productCode === RIGHTTRACK_CONTINUOUS_PRICING)
          .map((p) => ({
            productCode: p.productCode,
            telematicsOperators:
              p.telematicsOperators &&
              operators.map((op) => ({
                ...op,
                initialTelematicsStatus: undefined,
              })),
          })),
        bindValidationBypass,
      };

      onPost(body).then(closeWindow);
    },
    [bindValidationBypass, onPost, operators, telematicsProducts],
  );

  const handleCancel = useCallback(() => {
    closeWindow();
  }, []);

  const handleConsent = useCallback((consent: boolean) => {
    if (!consent) {
      closeWindow();
    }
  }, []);

  useEffect(() => {
    setProduct(telematicsProducts.find((x) => x.productCode === RIGHTTRACK_CONTINUOUS_PRICING) ?? newTelematicsProduct());
  }, [telematicsProducts]);

  useEffect(() => {
    if (isEmpty(transactionEffectiveDate) || isEmpty(policyEffectiveDate)) {
      setError && setError('Unable to load effective dates');
    }

    let ted = moment(transactionEffectiveDate);
    let ped = moment(policyEffectiveDate);

    if (ted.isAfter('2099-01-01') || ped.isBefore('1900-01-01')) {
      setError && setError('Unable to load effective dates');
    }
    let daysBetweenDates = ted.diff(ped, 'days');
    let anyOtherOptInNewBusiness = product.telematicsOperators.some(
      (operator) => (operator.priorPolicyTelematicsStatus || '') === RIGHTTRACK_CONTINUOUS_PRICING_DROPDOWN_VALUES.NewBusinessWindow.toString(),
    );

    console.info('isRTAdmin: ', isRTAdmin);

    setOperators(
      product.telematicsOperators.map((operator): TelematicsOperator => {
        return {
          ...operator,
          telematicsStatusCodes: [
            ...getContinuousPricingDropdownCodes(daysBetweenDates, operator.priorPolicyTelematicsStatus ?? '', anyOtherOptInNewBusiness, isRTAdmin, operator.telematicsStatus ?? ''),
          ],
        };
      }),
    );
  }, [product, policyEffectiveDate, transactionEffectiveDate, isRTAdmin, setError]);

  return (
    <Content fullWidth>
      <Content fullWidth className="mb-2">
        {!areAllParticipating(product) &&
            <SalesRepConfirmation onConsent={handleConsent} hasOperatorParticipation={isParticipating(product)} />}

        <GridRow>
          <GridCol base={12} className="p-2">
            Usage based insurance allows insurers to adjust premiums based on real driving data provided by the customer via technology
            <br />
            <br />
            <Heading type="h4-bold">Program Description</Heading>
            <Heading type="h6-bold">RightTrack Continuous Pricing</Heading>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
            minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
            voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
            deserunt mollit anim id est laborum.
            <br />
            <br />
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
            minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
            voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
            deserunt mollit anim id est laborum.
            <br />
          </GridCol>
        </GridRow>

        <Form onSubmit={handleSubmit} className='mw-100'>
          <GridRow gutters>
            <GridCol base={12}>
              <OperatorProgramSelection
                productCode={RIGHTTRACK_CONTINUOUS_PRICING}
                backgroundCss="yellow-background lm-round"
                isRTAdmin={isRTAdmin}
                telematicsOperators={operators}
                onChange={(e) => setOperators(e.target.value)}
              />
            </GridCol>
          </GridRow>

          <GridRow gutters>
            <GridCol base={12} className="d-flex justify-content-end px-4">
              <Button variant="primary" type="submit">
                Apply
              </Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </GridCol>
          </GridRow>
        </Form>

        <GridRow className="yellow-background lm-round p-2 ps-3">
          <GridCol base={12}>
            v{version} ({build})
          </GridCol>
        </GridRow>
      </Content>

      <DebugConsole src={{ product, operators }} />
    </Content>
  );
};

export default ProgramParticipation;
