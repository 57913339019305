import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PremiumSummary from '../components/PremiumSummary';
import AppContainer from '../containers/AppContainer';

export default function Router() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/PremiumSummary" name="premium-summary" component={PremiumSummary} />
        <Route path="//:id?" name="home" component={AppContainer} />
        <Route path="/:id?" name="home" component={AppContainer} />
      </Switch>
    </BrowserRouter>
  );
}

Router.displayName = 'Router';
